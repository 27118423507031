import { createSlice } from "@reduxjs/toolkit";
import { SearchInvoiceInterface } from "../interfaces/raports.interface";
import { fetchSearchInvoice } from "../api/raports.api";

export const defaultStateRaports: {
  searchInvoice: SearchInvoiceInterface;
} = {
  searchInvoice: {
    data: [],
    isLoading: true,
    isError: false,
    errorMessage: "",
  },
};

const raports = createSlice({
  name: "raports",
  initialState: defaultStateRaports,
  reducers: {},
  extraReducers: (builder) => {
    // fetchSearchInvoice  raport start
    builder.addCase(fetchSearchInvoice.pending, (state) => {
      return {
        ...state,
        searchInvoice: {
          ...defaultStateRaports.searchInvoice,
          isLoading: true,
        },
      };
    });
    builder.addCase(fetchSearchInvoice.rejected, (state, action: any) => {
      return {
        ...state,
        searchInvoice: {
          ...state.searchInvoice,
          isLoading: false,
          errorMessage: action.payload.message,
          isError: true,
        },
      };
    });
    builder.addCase(fetchSearchInvoice.fulfilled, (state, action) => {
      return {
        ...state,
        searchInvoice: {
          ...state.searchInvoice,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    // fetchSearchInvoice  raport end
  },
});

export default raports.reducer;
