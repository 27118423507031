import { createSlice } from "@reduxjs/toolkit";

import {
  fetchDrivers,
  fetchRoutesNomenclatures,
  fetchIntranodesNomenclatures,
} from "../api/assign.api";
import {
  AssignedDriverDataInterface,
  AssignedDriversInterface,
  IntranodNomenclatureDataInterface,
  IntranodesNomenclaturesInterface,
  RouteNomenclatureDataInterface,
  RoutesNomenclaturesInterface,
} from "../interfaces/assign.interface";

export interface StateAssignDriverInterface {
  assignedDriversData: AssignedDriversInterface;
  assignedDriverData: AssignedDriverDataInterface | null;
  routesNomenclatures: RoutesNomenclaturesInterface;
  routeNomenclatureData: RouteNomenclatureDataInterface | null;
  intranodesNomenclatures: IntranodesNomenclaturesInterface;
  intranodNomenclatureData: IntranodNomenclatureDataInterface | null;
}

export const defaultStateAssign: StateAssignDriverInterface = {
  assignedDriversData: {
    data: {
      current_page: 1,
      data: [],
      last_page: 1,
      perPage: 1,
      total: 1,
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  assignedDriverData: null,
  routesNomenclatures: {
    data: {
      current_page: 1,
      data: [],
      last_page: 1,
      perPage: 1,
      total: 1,
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  routeNomenclatureData: null,
  intranodesNomenclatures: {
    data: {
      current_page: 1,
      data: [],
      last_page: 1,
      perPage: 1,
      total: 1,
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  intranodNomenclatureData: null,
};

const assign = createSlice({
  name: "assign",
  initialState: defaultStateAssign,
  reducers: {
    setAssignDriver: (
      state,
      action: {
        payload: AssignedDriverDataInterface;
      },
    ) => {
      return {
        ...state,
        assignedDriverData: action.payload,
      };
    },
    setRoutesNomenclatures: (
      state,
      action: {
        payload: RouteNomenclatureDataInterface;
      },
    ) => {
      return {
        ...state,
        routeNomenclatureData: action.payload,
      };
    },
    setIntranodesNomenclatures: (
      state,
      action: {
        payload: IntranodNomenclatureDataInterface;
      },
    ) => {
      return {
        ...state,
        intranodNomenclatureData: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    // assign start
    builder.addCase(fetchDrivers.pending, (state) => {
      return {
        ...state,
        assignedDriversData: {
          ...state.assignedDriversData,
          isLoading: true,
          isError: false,
          errorMessage: "",
        },
      };
    });
    builder.addCase(fetchDrivers.rejected, (state, action: any) => {
      return {
        ...state,
        assignedDriversData: {
          ...state.assignedDriversData,
          isLoading: false,
          isError: true,
          errorMessage: action.payload.message,
        },
      };
    });
    builder.addCase(fetchDrivers.fulfilled, (state, action) => {
      return {
        ...state,
        assignedDriversData: {
          ...state.assignedDriversData,
          data: action.payload,
          isLoading: false,
          isError: false,
          errorMessage: "",
        },
      };
    });
    // assign end

    // assign nomroute start
    builder.addCase(fetchRoutesNomenclatures.pending, (state) => {
      return {
        ...state,
        routesNomenclatures: {
          ...state.routesNomenclatures,
          isLoading: true,
          isError: false,
          errorMessage: "",
        },
      };
    });
    builder.addCase(fetchRoutesNomenclatures.rejected, (state, action: any) => {
      return {
        ...state,
        routesNomenclatures: {
          ...state.routesNomenclatures,
          isLoading: false,
          isError: true,
          errorMessage: action.payload.message,
        },
      };
    });
    builder.addCase(fetchRoutesNomenclatures.fulfilled, (state, action) => {
      return {
        ...state,
        routesNomenclatures: {
          ...state.routesNomenclatures,
          data: action.payload,
          isLoading: false,
          isError: false,
          errorMessage: "",
        },
      };
    });
    // assign nomroute end

    // assign nomrouteintranod start
    builder.addCase(fetchIntranodesNomenclatures.pending, (state) => {
      return {
        ...state,
        intranodesNomenclatures: {
          ...state.intranodesNomenclatures,
          isLoading: true,
          isError: false,
          errorMessage: "",
        },
      };
    });
    builder.addCase(
      fetchIntranodesNomenclatures.rejected,
      (state, action: any) => {
        return {
          ...state,
          intranodesNomenclatures: {
            ...state.intranodesNomenclatures,
            isLoading: false,
            isError: true,
            errorMessage: action.payload.message,
          },
        };
      },
    );
    builder.addCase(fetchIntranodesNomenclatures.fulfilled, (state, action) => {
      return {
        ...state,
        intranodesNomenclatures: {
          ...state.intranodesNomenclatures,
          data: action.payload,
          isLoading: false,
          isError: false,
          errorMessage: "",
        },
      };
    });
    // assign nomrouteintranod end
  },
});

export const {
  setAssignDriver,
  setRoutesNomenclatures,
  setIntranodesNomenclatures,
} = assign.actions;

export default assign.reducer;
