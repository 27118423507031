import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { Formik } from "formik";
import Dropdown, { DropdownValue } from "../../../components/misc/Dropdown";
import FormButtons from "../../../components/misc/FormButtons";

import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import {
  fetchAssignDriverToRoute,
  fetchRoutesNomenclatures,
} from "../../../redux/api/assign.api";
import {
  checkEndpointStatus,
  endpointStatus,
} from "../../../helpers/apiHelper";
import DatePicker from "../../../components/misc/DatePicker";
import { dateForDatabase } from "../../../utils/dateAndTime";
import ContentCard from "../../../components/misc/ContentCard";

interface initialValuesInterface {
  selectedRoute: DropdownValue | null;
  selectedCar: DropdownValue | null;
  sofer_data_start: string;
  sofer_data_stop: string;
}

const initialValues: initialValuesInterface = {
  selectedRoute: null,
  selectedCar: null,
  sofer_data_start: new Date().toISOString(),
  sofer_data_stop: "",
};

const formikSchema = Yup.object({
  sofer_data_start: Yup.date()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .required("Va rugam introduceti data"),

  selectedRoute: Yup.object().required("Va rugam selectati ruta").nullable(),
  selectedCar: Yup.object().required("Va rugam selectati masina").nullable(),
});

function AssignDriverRoute({ withUpdate = false }) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [formikValues, setFormikValues] =
    useState<initialValuesInterface>(initialValues);
  const [isSaving, setIsSaving] = useState(false);

  const {
    assign: {
      routesNomenclatures: {
        data: { data: routesNomenclatures },
        isLoading: isLoadingRoutesNomenclatures,
      },
      assignedDriverData,
    },
    fleet: {
      fleetData: {
        data: { data: fleetData },
        isLoading: isLoadingFleetData,
      },
    },
  } = useAppSelector((state) => ({
    assign: state.assign,
    expedition: state.expedition,
    fleet: state.fleet,
  }));

  const { sofer_data_start, sofer_data_stop, id_ruta, id_user, nrauto } =
    assignedDriverData || {};

  useEffect(() => {
    dispatch(
      fetchRoutesNomenclatures({
        perPage: 10000,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (withUpdate && routesNomenclatures.length && fleetData.length) {
      const selectedRoute = routesNomenclatures?.find(
        (item) => item.id_ruta === id_ruta,
      );
      const selectedCar = fleetData?.find((item) => item.nrauto === nrauto);

      setFormikValues((prev) => ({
        ...prev,
        sofer_data_start: sofer_data_start || "",
        sofer_data_stop: sofer_data_stop || "",
        selectedRoute: selectedRoute
          ? {
              label: selectedRoute.denumire,
              id: selectedRoute.id_ruta,
            }
          : null,
        selectedCar: selectedCar
          ? {
              label: selectedCar.nrauto,
              id: selectedCar.id_auto,
            }
          : null,
      }));
    }
  }, [
    withUpdate,
    id_ruta,
    nrauto,
    sofer_data_start,
    sofer_data_stop,
    fleetData,
    routesNomenclatures,
  ]);

  const handleSave = async (value: initialValuesInterface) => {
    setIsSaving(true);

    const resultAction = await dispatch(
      fetchAssignDriverToRoute({
        ...value,
        id_user: id_user,
        id_ruta: value.selectedRoute?.id,
        id_auto: value.selectedCar?.id,
        sofer_data_start: dateForDatabase(value.sofer_data_start),
        sofer_data_stop: dateForDatabase(value.sofer_data_stop),
      }),
    );

    if (
      checkEndpointStatus(resultAction, fetchAssignDriverToRoute) !==
      endpointStatus.pending
    ) {
      setIsSaving(false);
    }

    if (
      checkEndpointStatus(resultAction, fetchAssignDriverToRoute) ===
      endpointStatus.fulfilled
    ) {
      navigate(-1);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const routesNomenclaturesDropdownValues = useMemo(
    () =>
      routesNomenclatures?.map((item) => ({
        label: item.denumire,
        id: item.id_ruta,
      })),
    [routesNomenclatures],
  );

  const fleetNomenclaturesDropdownValues = useMemo(
    () =>
      fleetData?.map((item) => ({
        label: item.nrauto,
        id: item.id_auto,
      })),
    [fleetData],
  );

  const sortedRoutesNomenclaturesDropdownValues =
    routesNomenclaturesDropdownValues.sort((a, b) => {
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

  return (
    <ContentCard
      isLoading={isLoadingRoutesNomenclatures || isLoadingFleetData}
      withShadow={false}
      withPadding={false}>
      <Formik
        enableReinitialize
        initialValues={formikValues}
        validationSchema={formikSchema}
        onSubmit={handleSave}>
        {({
          values,
          isSubmitting,
          errors,
          touched,
          handleSubmit,
          setFieldValue,
        }) => {
          const { selectedRoute, selectedCar } = values;

          return (
            <form
              className="form w-100"
              onSubmit={handleSubmit}
              noValidate
              id="kt_login_signin_form">
              <div className="d-md-flex d-block">
                <DatePicker
                  label="Data incepere valabilitate"
                  minDate={new Date()}
                  value={values.sofer_data_start}
                  handleChangeDate={(newDate) => {
                    setFieldValue("sofer_data_start", newDate);
                  }}
                  error={errors["sofer_data_start"]}
                  touched={touched["sofer_data_start"]}
                />

                <DatePicker
                  label="Data sfarsit valabilitate"
                  minDate={new Date()}
                  value={values.sofer_data_stop}
                  handleChangeDate={(newDate) => {
                    setFieldValue("sofer_data_stop", newDate);
                  }}
                  error={errors["sofer_data_stop"]}
                  touched={touched["sofer_data_stop"]}
                  withVerticalSpacer
                />
              </div>

              <Dropdown
                className="my-4"
                title={"Selecteaza ruta"}
                data={sortedRoutesNomenclaturesDropdownValues}
                singleValue={selectedRoute}
                setSingleValue={(value) => {
                  setFieldValue("selectedRoute", value);
                }}
                error={errors["selectedRoute"]}
                touched={touched["selectedRoute"]}
              />
              <Dropdown
                className="my-4"
                title={"Selecteaza masina"}
                data={fleetNomenclaturesDropdownValues}
                singleValue={selectedCar}
                setSingleValue={(value) => {
                  setFieldValue("selectedCar", value);
                }}
                error={errors["selectedCar"]}
                touched={touched["selectedCar"]}
              />

              <FormButtons
                goBackLabel="Anuleaza"
                goNextLabel="Salveaza"
                handleGoBack={handleCancel}
                nextButtonDisabled={isSubmitting}
                nextButtonLoading={isSaving}
              />
            </form>
          );
        }}
      </Formik>
    </ContentCard>
  );
}

export default AssignDriverRoute;
