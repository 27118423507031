import { useNavigate } from "react-router-dom";
import CustomButton from "../../../../../components/CustomButton";
import SvgIcon from "../../../../../helpers/SvgIcon";

import { useAppDispatch } from "../../../../../redux/hooks";
import { AssignedDriverDataInterface } from "../../../../../redux/interfaces/assign.interface";
import { assignRoutes } from "../../../../../router/routesConstants";
import { setAssignDriver } from "../../../../../redux/reducer/assign.reducer";

function AssignDriverActionButton({
  itemId,
  crudData,
}: {
  itemId: string;
  crudData: AssignedDriverDataInterface;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleGoToAssignDriver = () => {
    dispatch(setAssignDriver(crudData));
    navigate(`${itemId}/${assignRoutes.assignDriver}`);
  };

  return (
    <div>
      <CustomButton
        variant="contained"
        className="mt-1 me-1"
        tooltipTitle="Asignare sofer"
        onClick={handleGoToAssignDriver}>
        <SvgIcon type="DOCUMENTS" />
      </CustomButton>
    </div>
  );
}

export default AssignDriverActionButton;
