import { useEffect, useState } from "react";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import {
  clearFleetFeatureData,
  clearFleetTypeData,
} from "../../../redux/reducer/fleet.reducer";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  fetchAddOrUpdateFeature,
  fetchFleetTypes,
} from "../../../redux/api/fleet.api";
import {
  checkEndpointStatus,
  endpointStatus,
} from "../../../helpers/apiHelper";
import useRoutesHelper from "../../../hooks/useRoutesHelper";

import FormButtons from "../../../components/misc/FormButtons";
import TextInput from "../../../components/misc/TextInput";
import ContentCard from "../../../components/misc/ContentCard";
import Dropdown from "../../../components/misc/Dropdown";

interface initialValuesInterface {
  denumire: string;
  model_capacit: string;
  model_ech: string;
  model_ser: string;
  tip: number | string | null;
}

const initialValues: initialValuesInterface = {
  denumire: "",
  model_capacit: "",
  model_ech: "",
  model_ser: "",
  tip: null,
};

const formikSchema = Yup.object({
  denumire: Yup.string().required("Denumirea este obligatorie"),
  model_ech: Yup.string().required("Model echipament este obligatorie"),
  model_capacit: Yup.string().required("Model capacitate este obligatorie"),
  model_ser: Yup.string().required("Model serie este obligatorie"),
});

function FleetDocumentsEdit({ withUpdate }: { withUpdate?: boolean }) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { routeMainParam, routeParams } = useRoutesHelper();
  const { carId } = routeMainParam as { carId: string };
  const { nrauto } = routeParams as { nrauto: string };

  const {
    fleet: {
      fleetFeatureData,
      fleetTypeData: { data: fleetTypesData, isLoading: fleetTypesLoading },
    },
  } = useAppSelector((state) => ({
    fleet: state.fleet,
  }));

  const [formikValues, setFormikValues] =
    useState<initialValuesInterface>(initialValues);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(fetchFleetTypes({ table: "nom_flota_calitate" }));

    return () => {
      dispatch(clearFleetTypeData());
      dispatch(clearFleetFeatureData());
    };
  }, [dispatch]);

  useEffect(() => {
    if (fleetTypesData.length) {
      // we set an initial dropdown data
      const initialTypeId = fleetTypesData[0].id;
      setFormikValues((prev) => {
        return {
          ...prev,
          tip: initialTypeId,
        };
      });

      if (fleetFeatureData && withUpdate) {
        setFormikValues((prev) => ({
          ...prev,
          denumire: fleetFeatureData.denumire,
          model_capacit: fleetFeatureData.model_capacit,
          model_ech: fleetFeatureData.model_ech,
          model_ser: fleetFeatureData.model_ser,
          tip: fleetFeatureData.tip,
          valoare: fleetFeatureData.valoare,
        }));
      }
    }
  }, [fleetTypesData, fleetFeatureData, withUpdate]);

  const handleSaveProductDetails = async (value: initialValuesInterface) => {
    setIsLoading(true);

    const resultAction = await dispatch(
      fetchAddOrUpdateFeature({
        ...fleetFeatureData,
        ...value,
        id_auto: carId,
      }),
    );

    if (
      checkEndpointStatus(resultAction, fetchAddOrUpdateFeature) !==
      endpointStatus.pending
    ) {
      setIsLoading(false);
    }

    if (
      checkEndpointStatus(resultAction, fetchAddOrUpdateFeature) ===
      endpointStatus.fulfilled
    ) {
      navigate(-1);
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  if (!fleetFeatureData && withUpdate) {
    return (
      <ContentCard>
        <h1>Document inexistent</h1>
      </ContentCard>
    );
  }

  const title =
    (withUpdate
      ? "Editeaza caracteristici echipament pentru masina"
      : "Adauga echipament pentru masina") + ` ${nrauto || ""}`;

  return (
    <ContentCard
      isLoading={fleetTypesLoading}
      cardTitle={title}
      cardHeaderClassName="text-center"
      isLoadingClassName="d-flex flex-column justify-content-center align-items-center vh-75"
      CardHeader={() => <></>}>
      <Formik
        enableReinitialize
        initialValues={formikValues}
        validationSchema={formikSchema}
        onSubmit={handleSaveProductDetails}>
        {({
          isValid,
          errors,
          touched,
          values,
          getFieldProps,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => {
          const selectedType = fleetTypesData?.find(
            (item) => item.id === values.tip,
          );
          return (
            <form
              className="form w-100"
              onSubmit={handleSubmit}
              noValidate
              id="kt_login_signin_form">
              <Dropdown
                className="my-4"
                title="Selecteaza tipul echipamentului"
                data={fleetTypesData}
                singleValue={selectedType}
                setSingleValue={(value) => {
                  setFieldValue("tip", value?.id);
                }}
              />
              <TextInput
                type="text"
                label="Denumire"
                name="denumire"
                error={errors["denumire"]}
                touched={touched["denumire"]}
                inputProps={getFieldProps("denumire")}
              />

              <div className="d-md-flex d-block">
                <TextInput
                  type="text"
                  label="Model echipament"
                  name="model_ech"
                  error={errors["model_ech"]}
                  touched={touched["model_ech"]}
                  inputProps={getFieldProps("model_ech")}
                />
                <TextInput
                  type="text"
                  label="Model capacitate"
                  name="model_capacit"
                  error={errors["model_capacit"]}
                  touched={touched["model_capacit"]}
                  inputProps={getFieldProps("model_capacit")}
                  withVerticalSpacer
                />
                <TextInput
                  type="text"
                  label="Model serie"
                  name="model_ser"
                  error={errors["model_ser"]}
                  touched={touched["model_ser"]}
                  inputProps={getFieldProps("model_ser")}
                  withUpperCasing
                />
              </div>

              <FormButtons
                goBackLabel="Inapoi"
                goNextLabel={isSubmitting ? "Se incarca..." : "Salveaza"}
                nextButtonDisabled={!isValid || isSubmitting}
                nextButtonLoading={isSubmitting || isLoading}
                handleGoBack={handleGoBack}
              />
            </form>
          );
        }}
      </Formik>
    </ContentCard>
  );
}

export default FleetDocumentsEdit;
