import { useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

import FormButtons from "../../../components/misc/FormButtons";
import TextInput from "../../../components/misc/TextInput";
import ContentCard from "../../../components/misc/ContentCard";
import Dropdown from "../../../components/misc/Dropdown";

import {
  fetchAddOrUpdateCar,
  fetchFleetTypes,
} from "../../../redux/api/fleet.api";
import {
  checkEndpointStatus,
  endpointStatus,
} from "../../../helpers/apiHelper";
import {
  clearFleetCarData,
  clearFleetTypeData,
} from "../../../redux/reducer/fleet.reducer";
import useRoutesHelper from "../../../hooks/useRoutesHelper";

interface initialValuesInterface {
  nrauto: string;
  marca: string;
  model: string;
  serie_sas: string;
  combustibil: number | null;
  rezervor: number | null;
  cpc_transp: number | null;
  admis_osie: number | null;
  revizie_km: number | null;
  revizie_timp: number | null;
  anv_iarna: string | null;
  anv_vara: string | null;
  ic_tip: number | null;
  ic_ser: string | null;
}

const initialValues: initialValuesInterface = {
  nrauto: "",
  marca: "",
  model: "",
  serie_sas: "",
  combustibil: 1,
  rezervor: null,
  cpc_transp: null,
  admis_osie: null,
  revizie_km: null,
  revizie_timp: null,
  anv_iarna: null,
  anv_vara: null,
  ic_tip: 11,
  ic_ser: null,
};

const formikSchema = Yup.object({
  nrauto: Yup.string().required("Nr auto este obligatoriu"),
  marca: Yup.string().required("Marca este obligatorie"),
  model: Yup.string().required("Model este obligatoriu"),
  serie_sas: Yup.string().required("Serie SAS este obligatorie"),
  rezervor: Yup.number()
    .required("Rezervor este obligatoriu")
    .nullable()
    .min(1, "Doar numere pozitive mai mari decat 0"),
  cpc_transp: Yup.number()
    .required("CPC Transp este obligatoriu")
    .nullable()
    .min(1, "Doar numere pozitive mai mari decat 0"),
  admis_osie: Yup.number()
    .required("Admis Osie este obligatoriu")
    .nullable()
    .min(1, "Doar numere pozitive mai mari decat 0"),
  revizie_km: Yup.number()
    .required("Revizie KM este obligatorie")
    .nullable()
    .min(1, "Doar numere pozitive mai mari decat 0"),
  revizie_timp: Yup.number()
    .required("Revizie Timp este obligatoriu")
    .nullable()
    .min(1, "Doar numere pozitive mai mari decat 0"),
  anv_iarna: Yup.string().nullable(),
  anv_vara: Yup.string().nullable(),
  ic_tip: Yup.number().nullable(),
  ic_ser: Yup.string().nullable(),
});

function FleetDocumentsEdit({ withUpdate }: { withUpdate?: boolean }) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { routeMainParam, routeParams } = useRoutesHelper();
  const { carId } = routeMainParam as { carId: string };
  const { nrauto } = routeParams as { nrauto: string };
  ``;

  const {
    fleet: {
      fleetCarData,
      fleetTypeData: { data: fleetTypesData, isLoading: fleetTypesLoading },
    },
  } = useAppSelector((state) => ({
    fleet: state.fleet,
  }));

  const [formikValues, setFormikValues] =
    useState<initialValuesInterface>(initialValues);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (fleetCarData && withUpdate) {
      setFormikValues({
        nrauto: fleetCarData.nrauto,
        marca: fleetCarData.marca,
        model: fleetCarData.model,
        serie_sas: fleetCarData.serie_sas,
        combustibil: fleetCarData.combustibil,
        rezervor: fleetCarData.rezervor,
        cpc_transp: fleetCarData.cpc_transp,
        admis_osie: fleetCarData.admis_osie,
        revizie_km: fleetCarData.revizie_km,
        revizie_timp: fleetCarData.revizie_timp,
        anv_iarna: fleetCarData.anv_iarna,
        anv_vara: fleetCarData.anv_vara,
        ic_tip: fleetCarData.ic_tip,
        ic_ser: fleetCarData.ic_ser,
      });
    }
  }, [fleetCarData, withUpdate]);

  useEffect(() => {
    dispatch(fetchFleetTypes({ table: "nom_flota_auto" }));

    return () => {
      dispatch(clearFleetTypeData());
    };
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearFleetCarData());
    };
  }, [dispatch]);
  [];

  const handleSaveProductDetails = async (value: initialValuesInterface) => {
    setIsLoading(true);

    const resultAction = await dispatch(
      fetchAddOrUpdateCar({
        ...fleetCarData,
        ...value,
        id_auto: carId,
      }),
    );

    if (
      checkEndpointStatus(resultAction, fetchAddOrUpdateCar) !==
      endpointStatus.pending
    ) {
      setIsLoading(false);
    }

    if (
      checkEndpointStatus(resultAction, fetchAddOrUpdateCar) ===
      endpointStatus.fulfilled
    ) {
      navigate(-1);
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const energySourcesTypesData = useMemo(() => {
    return fleetTypesData?.filter((item) => item.camp_tabela === "combustibil");
  }, [fleetTypesData]);

  const energyRingTypesData = useMemo(() => {
    return fleetTypesData?.filter((item) => item.camp_tabela === "ic_tip");
  }, [fleetTypesData]);

  if (!fleetCarData && withUpdate) {
    return (
      <ContentCard>
        <h1>Document inexistent</h1>
      </ContentCard>
    );
  }

  const title =
    (withUpdate
      ? "Editeaza informatii autovehicul"
      : "Adauga informatii autovehicul") + ` ${nrauto || ""}`;

  return (
    <ContentCard
      cardTitle={title}
      cardHeaderClassName="text-center"
      isLoadingClassName="d-flex flex-column justify-content-center align-items-center vh-75"
      isLoading={fleetTypesLoading}
      CardHeader={() => <></>}>
      <Formik
        enableReinitialize
        initialValues={formikValues}
        validationSchema={formikSchema}
        onSubmit={handleSaveProductDetails}>
        {({
          isValid,
          errors,
          touched,
          values,
          getFieldProps,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => {
          const selectedEnergySourceType = energySourcesTypesData?.find(
            (item) => item.id === values.combustibil,
          );

          const selectedEgergyRingType = energyRingTypesData?.find(
            (item) => item.id === values.ic_tip,
          );

          return (
            <form
              className="form w-100"
              onSubmit={handleSubmit}
              noValidate
              id="kt_login_signin_form">
              <div className="d-md-flex d-block">
                <TextInput
                  type="text"
                  label="Numar de inmatriculare"
                  name="nrauto"
                  error={errors["nrauto"]}
                  touched={touched["nrauto"]}
                  inputProps={getFieldProps("nrauto")}
                  withVerticalSpacer
                  withUpperCasing
                />
                <TextInput
                  type="text"
                  label="Marca"
                  name="marca"
                  error={errors["marca"]}
                  touched={touched["marca"]}
                  inputProps={getFieldProps("marca")}
                  withUpperCasing
                />
              </div>

              <div className="d-md-flex d-block">
                <TextInput
                  type="text"
                  label="Tip/Varianta/Denumire comercială"
                  name="model"
                  error={errors["model"]}
                  touched={touched["model"]}
                  inputProps={getFieldProps("model")}
                  withVerticalSpacer
                  withUpperCasing
                />
                <TextInput
                  type="text"
                  label="Numar de identificare"
                  name="serie_sas"
                  error={errors["serie_sas"]}
                  touched={touched["serie_sas"]}
                  inputProps={getFieldProps("serie_sas")}
                  withUpperCasing
                />
              </div>
              <div className="d-md-flex d-block">
                <Dropdown
                  className="me-1"
                  title="Sursa de energie"
                  data={energySourcesTypesData}
                  singleValue={selectedEnergySourceType}
                  setSingleValue={(value) => {
                    setFieldValue("combustibil", value?.id);
                  }}
                />

                <TextInput
                  type="number"
                  label="Capacitate rezervor"
                  name="rezervor"
                  error={errors["rezervor"]}
                  touched={touched["rezervor"]}
                  inputProps={getFieldProps("rezervor")}
                />
              </div>
              <div className="d-md-flex d-block">
                <TextInput
                  type="number"
                  label="Masa maxima tehnic admisibila (kg)"
                  name="cpc_transp"
                  error={errors["cpc_transp"]}
                  touched={touched["cpc_transp"]}
                  inputProps={getFieldProps("cpc_transp")}
                  withVerticalSpacer
                />
                <TextInput
                  type="number"
                  label="Masa maxima a axei 1"
                  name="admis_osie"
                  error={errors["admis_osie"]}
                  touched={touched["admis_osie"]}
                  inputProps={getFieldProps("admis_osie")}
                />
              </div>
              <div className="d-md-flex d-block">
                <TextInput
                  type="number"
                  label="Revizie KM"
                  name="revizie_km"
                  error={errors["revizie_km"]}
                  touched={touched["revizie_km"]}
                  inputProps={getFieldProps("revizie_km")}
                  withVerticalSpacer
                />
                <TextInput
                  type="number"
                  label="Revizie Timp"
                  name="revizie_timp"
                  error={errors["revizie_timp"]}
                  touched={touched["revizie_timp"]}
                  inputProps={getFieldProps("revizie_timp")}
                />
              </div>

              <div className="d-md-flex d-block">
                <TextInput
                  type="text"
                  label="Anv Iarna"
                  name="anv_iarna"
                  error={errors["anv_iarna"]}
                  touched={touched["anv_iarna"]}
                  inputProps={getFieldProps("anv_iarna")}
                  withVerticalSpacer
                  withUpperCasing
                />
                <TextInput
                  type="text"
                  label="Anv Vara"
                  name="anv_vara"
                  error={errors["anv_vara"]}
                  touched={touched["anv_vara"]}
                  inputProps={getFieldProps("anv_vara")}
                  withUpperCasing
                />
              </div>
              <div className="d-md-flex d-block">
                <Dropdown
                  className="me-1"
                  title="Tip Inel Combustibil"
                  data={energyRingTypesData}
                  singleValue={selectedEgergyRingType}
                  setSingleValue={(value) => {
                    setFieldValue("ic_tip", value?.id);
                  }}
                />
                <TextInput
                  type="text"
                  label="Serie Inel Combustibil"
                  name="ic_ser"
                  error={errors["ic_ser"]}
                  touched={touched["ic_ser"]}
                  inputProps={getFieldProps("ic_ser")}
                />
              </div>

              <FormButtons
                goBackLabel="Inapoi"
                goNextLabel={isSubmitting ? "Se incarca..." : "Salveaza"}
                nextButtonDisabled={!isValid || isSubmitting}
                nextButtonLoading={isSubmitting || isLoading}
                handleGoBack={handleGoBack}
              />
            </form>
          );
        }}
      </Formik>
    </ContentCard>
  );
}

export default FleetDocumentsEdit;
