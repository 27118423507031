export const errorMessageFormat = (err: any) => {
  let receivedErrorMessage = "";
  let errorMessage = "";
  if (err.response?.data) {
    receivedErrorMessage = err.response.data;
  }
  if (err.response?.data?.message?.length > 0) {
    receivedErrorMessage = err.response.data.message;
  } else if (err.message?.length > 0) {
    receivedErrorMessage = err.message;
  } else if (err.error?.message?.length > 0) {
    receivedErrorMessage = err.error.message;
  } else if (err.response?.data?.error?.message.length > 0) {
    receivedErrorMessage = err.response.data.error.message;
  } else if (err.response?.data?.error?.length > 0) {
    receivedErrorMessage = err.response.data.error;
  } else if (err.response?.data?.length > 0) {
    receivedErrorMessage = err.response.data;
  } else if (err.response?.statusText?.length > 0) {
    receivedErrorMessage = err.response.statusText;
  } else {
    receivedErrorMessage = "Eroare neasteptata";
  }

  switch (receivedErrorMessage) {
    case "Request failed with status code 404":
      errorMessage = "Nu exista acest endpoint";
      break;

    default:
      errorMessage = receivedErrorMessage;
      break;
  }

  return errorMessage;
};
