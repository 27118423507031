import { useCallback } from "react";
import CustomButton from "../../../../components/CustomButton";
import TableWidget from "../../../../components/tables/TableWidget";
import SvgIcon from "../../../../helpers/SvgIcon";
import useRoutesHelper from "../../../../hooks/useRoutesHelper";
import { fetchFleetFeatureDocuments } from "../../../../redux/api/fleet.api";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { clearFleetFeatureDocuments } from "../../../../redux/reducer/fleet.reducer";
import Text from "../../../../styleguide/Text";
import FleetFeatureDocumentsActionButton from "./components/TableActionsButtonsComponents/FleetFeatureDocumentsActionButton";
import {
  fleetFeatureDocumentsTableHeader,
  geFleetFeatureDocumentsTableData,
} from "./components/tableConfigs/fleetFeatureDocumentsTableConfig";

function FleetFeatureDocuments() {
  const { routeMainParam, routeParams } = useRoutesHelper();

  const { carId, featureId } = routeMainParam as {
    carId: string;
    featureId: string;
  };
  const { nrauto } = routeParams as { nrauto: string };

  const dispatch = useAppDispatch();

  const {
    fleet: {
      fleetFeatureDocuments: {
        data: fleetFeatureDocumentsData,
        isLoading: isLoadingFleet,
      },
    },
  } = useAppSelector((state) => ({
    fleet: state.fleet,
  }));

  const handleTableActions = () => {
    if (carId) {
      dispatch(fetchFleetFeatureDocuments({ id: featureId }));
    }
  };

  const handleUnmount = useCallback(() => {
    dispatch(clearFleetFeatureDocuments());
  }, [dispatch]);

  return (
    <TableWidget
      CustomHeaderComponent={() => (
        <div className="d-flex align-items-center justify-content-between">
          <Text
            variant="h3"
            component="h3"
            className="card-title mb-4"
            gutterBottom>
            Documente echipament auto {nrauto || ""} care asigura conformitatea
            transportului{" "}
          </Text>
          <CustomButton
            variant="contained"
            className="mt-1 me-1"
            tooltipTitle="Introducere document"
            navigate="create">
            <SvgIcon type="ADD" />
          </CustomButton>
        </div>
      )}
      tableHeaderData={fleetFeatureDocumentsTableHeader}
      tableItemsData={geFleetFeatureDocumentsTableData(
        fleetFeatureDocumentsData,
      )}
      handleTableActions={handleTableActions}
      handleUnmount={handleUnmount}
      borderedRow
      tableLoading={isLoadingFleet}
      ActionButtonsComponent={(props: any) => (
        <FleetFeatureDocumentsActionButton {...props} />
      )}
      showSearchForCategories={false}
      withTablePagination={false}
    />
  );
}

export default FleetFeatureDocuments;
