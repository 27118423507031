import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchUser } from "../api/user.api";
import { isDevelop } from "../../helpers/apiHelper";
import userRoles from "../../constants/userRoles";

export interface StateUserInterface {
  id_user: number | null;
  nume: string;
  otherActivity: string | null;
  prenume: string;
  userMobileRoutesAccess: string[];
  userRoutesAccess: string[];
  user_dept: number | null;
  user_role: number | null;
  username: string;
  isLoadingUser: boolean;
  isErrorUser: boolean;
  errorMessageUser: string;
}

export const defaultStateUser: StateUserInterface = {
  id_user: null,
  otherActivity: null,
  nume: "",
  prenume: "",
  userMobileRoutesAccess: [],
  userRoutesAccess: [],
  user_dept: null,
  user_role: null,
  username: "",
  isLoadingUser: false,
  isErrorUser: false,
  errorMessageUser: "",
};

const user = createSlice({
  name: "user",
  initialState: defaultStateUser,
  reducers: {
    setUser(
      state,
      action: PayloadAction<{
        token: string;
      }>,
    ) {
      return {
        ...state,
        isLoadingUser: false,
        token: action.payload.token,
      };
    },
    removeUser() {
      return { ...defaultStateUser, isLoadingUser: false };
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed\
    builder.addCase(fetchUser.pending, () => {
      return {
        ...defaultStateUser,
        isLoadingUser: true,
        errorMessageUser: "",
        isErrorUser: false,
      };
    });
    builder.addCase(fetchUser.rejected, (state, action: any) => {
      return {
        ...state,
        isLoadingUser: false,
        errorMessageUser: action.payload?.message,
        isErrorUser: true,
      };
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      const roles = isDevelop ? Object.values(userRoles) : [];
      return {
        ...state,
        isLoadingUser: false,
        ...action.payload,
        // if you add a new page and wan't to test it before it is added to the database, hardcode it below
        userRoutesAccess: [...action.payload.userRoutesAccess, ...roles],
      };
    });
  },
});

export const { setUser, removeUser } = user.actions;

export default user.reducer;
