import { TableHeaderCategoryConfig } from "../../../../components/tables/tableContext/TableContext";
import { ScheduleWorkingRoutesNodesListInterface } from "../../../../redux/interfaces/expedition.interface";

export const nodesTableHeader: TableHeaderCategoryConfig[] = [
  {
    value: "Id ruta",
    minW: 125,
  },
  {
    value: "Denumire",
    minW: 125,
  },
  {
    value: "Sofer",
    minW: 125,
  },
  {
    value: "Auto",
    minW: 125,
  },
];

export const getNodesTableData: any = (
  data: ScheduleWorkingRoutesNodesListInterface[],
) =>
  data?.map((item: any) => {
    return {
      id: item.id_nod,
      crudData: item,
      data: [
        {
          title: item.id_nod,
        },
        {
          title: item.nodNume,
        },
        {
          title: item.sofer || "-",
        },
        {
          title: item.auto || "-",
        },
      ],
    };
  });
