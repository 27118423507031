import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { fetchShowDocs } from "../../../redux/api/fleet.api";
import useRoutesHelper from "../../../hooks/useRoutesHelper";
import {
  fleetDocumentsTableHeader,
  getFleetDocumentsTableData,
} from "./components/tableConfigs/fleetDocumentsTableConfig";
import FleetDocumentsActionButton from "./components/TableActionsButtonsComponents/FleetDocumentsActionButton";

import TableWidget from "../../../components/tables/TableWidget";
import Text from "../../../styleguide/Text";
import CustomButton from "../../../components/CustomButton";
import SvgIcon from "../../../helpers/SvgIcon";
import { clearFleetDocuments } from "../../../redux/reducer/fleet.reducer";
import { useCallback } from "react";

function FleetDocuments() {
  const { routeMainParam, routeParams } = useRoutesHelper();

  const { carId } = routeMainParam as { carId: string; nrauto: string };
  const { nrauto } = routeParams as { nrauto: string };

  const dispatch = useAppDispatch();

  const {
    fleet: {
      fleetDocuments: { data: fleetDocumentsData, isLoading: isLoadingFleet },
    },
  } = useAppSelector((state) => ({
    fleet: state.fleet,
  }));

  const handleTableActions = () => {
    if (carId) {
      dispatch(fetchShowDocs({ id: carId }));
    }
  };

  const handleUnmount = useCallback(() => {
    dispatch(clearFleetDocuments());
  }, [dispatch]);

  return (
    <TableWidget
      CustomHeaderComponent={() => (
        <div className="d-flex align-items-center justify-content-between">
          <Text
            variant="h3"
            component="h3"
            className="card-title mb-4"
            gutterBottom>
            Documente masina {nrauto}
          </Text>
          <CustomButton
            variant="contained"
            className="mt-1 me-1"
            tooltipTitle="Creare"
            navigate={`create?nrauto=${nrauto}`}>
            <SvgIcon type="ADD" />
          </CustomButton>
        </div>
      )}
      tableHeaderData={fleetDocumentsTableHeader}
      tableItemsData={getFleetDocumentsTableData(fleetDocumentsData)}
      handleTableActions={handleTableActions}
      handleUnmount={handleUnmount}
      borderedRow
      tableLoading={isLoadingFleet}
      ActionButtonsComponent={(props: any) => (
        <FleetDocumentsActionButton {...props} />
      )}
      showSearchForCategories={false}
      withTablePagination={false}
    />
  );
}

export default FleetDocuments;
