import { useCallback } from "react";

import TableWidget from "../../../components/tables/TableWidget";
import { TableActions } from "../../../components/tables/tableContext/TableContext";

import { getWorkingRoutes } from "../../../redux/api/expedition.api";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { clearScheduleWorkingRoutes } from "../../../redux/reducer/expedition.reducer";

import {
  getExpeditionScheduleWorkingRoutesTableData,
  expeditionScheduleWorkingRoutesTableHeader,
} from "./components/tableConfigs/expeditionScheduleWorkingRoutesTableConfig";
import PickingScheduleWorkingRoutesTableActionButtons from "./components/TableActionsButtonsComponents/ExpeditionScheduleWorkingRoutesTableActionButtons";
import { crudRoutes } from "../../../router/routesConstants";
import FormHeader from "../../../components/misc/FormHeader";

function ExpeditionScheduleRoutes() {
  const dispatch = useAppDispatch();

  const {
    expedition: {
      scheduleWorkingRoutes: { data: scheduleWorkingRoutesData, isLoading },
    },
  } = useAppSelector((state) => ({
    expedition: state.expedition,
  }));

  const handleTableActions = useCallback(
    (action: TableActions) => {
      if (action.tablePagination.page && action.tablePagination.rowsPerPage) {
        dispatch(
          getWorkingRoutes({
            page: action.tablePagination.page,
            perPage: action.tablePagination.rowsPerPage,
          }),
        );
      }
    },
    [dispatch],
  );

  const handleUnmount = useCallback(() => {
    dispatch(clearScheduleWorkingRoutes());
  }, [dispatch]);

  return (
    <TableWidget
      CustomHeaderComponent={() => (
        <FormHeader
          title="Rute de transport"
          buttonRoute={crudRoutes.create}
          buttonIcon="ADD"
        />
      )}
      tableHeaderData={expeditionScheduleWorkingRoutesTableHeader}
      tableItemsData={getExpeditionScheduleWorkingRoutesTableData(
        scheduleWorkingRoutesData?.data,
      )}
      handleTableActions={handleTableActions}
      handleUnmount={handleUnmount}
      totalItems={scheduleWorkingRoutesData.total}
      tableLoading={isLoading}
      ActionButtonsComponent={PickingScheduleWorkingRoutesTableActionButtons}
      showSearchForCategories={false}
      borderedRow
    />
  );
}

export default ExpeditionScheduleRoutes;
