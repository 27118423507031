import { TableActions } from "../../../components/tables/tableContext/TableContext";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { fetchDrivers } from "../../../redux/api/assign.api";
import {
  assignedDriversDataTableHeader,
  getAssignedDriversTableData,
} from "./components/tableConfigs/assignedDriversTableConfig";

import AssignActionButton from "./components/TableActionsButtonsComponents/AssignDriverActionButton";
import TableWidget from "../../../components/tables/TableWidget";

function AssignDrivers() {
  const dispatch = useAppDispatch();

  const {
    assign: {
      assignedDriversData: {
        data: { data: assignedDriversData, total },
        isLoading: isLoadingAssign,
      },
    },
  } = useAppSelector((state) => ({
    assign: state.assign,
  }));

  const handleTableActions = (action: TableActions) => {
    if (
      action.tablePagination.page &&
      action.tablePagination.rowsPerPage &&
      action.tableSearch
    ) {
      dispatch(
        fetchDrivers({
          page: action.tablePagination.page,
          perPage: action.tablePagination.rowsPerPage,
          nume: action.tableSearch.nume,
          prenume: action.tableSearch.prenume,
        }),
      );
    }
  };

  return (
    <TableWidget
      tableTitle="Alocare ruta"
      tableHeaderData={assignedDriversDataTableHeader}
      tableItemsData={getAssignedDriversTableData(assignedDriversData)}
      totalItems={total}
      borderedRow
      tableLoading={isLoadingAssign}
      ActionButtonsComponent={(props: any) => <AssignActionButton {...props} />}
      handleTableActions={handleTableActions}
    />
  );
}

export default AssignDrivers;
