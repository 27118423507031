import { useNavigate } from "react-router-dom";

import { crudRoutes } from "../../../../../../router/routesConstants";

import { useAppDispatch } from "../../../../../../redux/hooks";
import { FleetFeatureDocumentsDataInterface } from "../../../../../../redux/interfaces/fleet.interface";
import { setFleetFeatureDocumentData } from "../../../../../../redux/reducer/fleet.reducer";
import { fetchDeleteFeatureDocument } from "../../../../../../redux/api/fleet.api";

import CustomButton from "../../../../../../components/CustomButton";
import SvgIcon from "../../../../../../helpers/SvgIcon";

function FleetFeatureDocumentsActionButton({
  crudData,
  itemId,
}: {
  itemId: string;
  crudData: FleetFeatureDocumentsDataInterface;
  tableContext: any;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleEdit = () => {
    dispatch(setFleetFeatureDocumentData(crudData));
    navigate(`${itemId}/${crudRoutes.edit}`);
  };

  const handleDelete = () => {
    dispatch(fetchDeleteFeatureDocument({ id: itemId }));
  };

  return (
    <div>
      <CustomButton
        variant="contained"
        className="mt-1 me-1"
        tooltipTitle="Editeaza detalii document"
        onClick={handleEdit}>
        <SvgIcon type="EDIT" />
      </CustomButton>
      <CustomButton
        variant="contained"
        color="error"
        className="mt-1 me-1"
        tooltipTitle="Stergere document"
        withConfirmationModal={{
          modalTitle: `Doriti sa stergeti acest document?`,
          modalLeftButtonColor: "inherit",
          modalRightButtonOnClick: handleDelete,
          modalRightButtonText: "Sterge",
          modalLeftButtonText: "Anuleaza",
        }}>
        <SvgIcon type="DELETE" />
      </CustomButton>
    </div>
  );
}

export default FleetFeatureDocumentsActionButton;
