import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { fetchFleetFeatures } from "../../../redux/api/fleet.api";
import useRoutesHelper from "../../../hooks/useRoutesHelper";
import TableWidget from "../../../components/tables/TableWidget";
import {
  fleetFeaturesTableHeader,
  getFleetFeaturesTableData,
} from "./components/tableConfigs/fleetFeaturesTableConfig";
import FleetFeaturesActionButton from "./components/TableActionsButtonsComponents/FleetFeaturesActionButton";
import Text from "../../../styleguide/Text";
import CustomButton from "../../../components/CustomButton";
import SvgIcon from "../../../helpers/SvgIcon";
import { useCallback } from "react";
import { clearFleetFeatures } from "../../../redux/reducer/fleet.reducer";

function FleetFeatures() {
  const { routeMainParam, routeParams } = useRoutesHelper();

  const { carId } = routeMainParam as { carId: string };
  const { nrauto } = routeParams as { nrauto: string };

  const dispatch = useAppDispatch();

  const {
    fleet: {
      fleetFeatures: { data: fleetFeaturesData, isLoading: isLoadingFleet },
    },
  } = useAppSelector((state) => ({
    fleet: state.fleet,
  }));

  const handleTableActions = () => {
    if (carId) {
      dispatch(fetchFleetFeatures({ id: carId }));
    }
  };

  const handleUnmount = useCallback(() => {
    dispatch(clearFleetFeatures());
  }, [dispatch]);

  return (
    <TableWidget
      CustomHeaderComponent={() => (
        <div className="d-flex align-items-center justify-content-between">
          <Text
            variant="h3"
            component="h3"
            className="card-title mb-4"
            gutterBottom>
            Echipamente auto {nrauto || ""} care asigura conformitatea
            transportului{" "}
          </Text>
          <CustomButton
            variant="contained"
            className="mt-1 me-1"
            tooltipTitle="Adauga echipament nou"
            navigate={`create?nrauto=${nrauto}`}>
            <SvgIcon type="ADD" />
          </CustomButton>
        </div>
      )}
      tableHeaderData={fleetFeaturesTableHeader}
      tableItemsData={getFleetFeaturesTableData(fleetFeaturesData)}
      handleTableActions={handleTableActions}
      handleUnmount={handleUnmount}
      borderedRow
      tableLoading={isLoadingFleet}
      ActionButtonsComponent={(props: any) => (
        <FleetFeaturesActionButton {...props} />
      )}
      showSearchForCategories={false}
      withTablePagination={false}
    />
  );
}

export default FleetFeatures;
