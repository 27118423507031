import { useNavigate } from "react-router-dom";
import CustomButton from "../../../../../components/CustomButton";
import SvgIcon from "../../../../../helpers/SvgIcon";
import { FleetAllDocumentsDataInterface } from "../../../../../redux/interfaces/fleet.interface";
import { fleetRoutes } from "../../../../../router/routesConstants";

function FleetAllDocumentsActionButton({
  crudData,
}: {
  crudData: FleetAllDocumentsDataInterface;
}) {
  const navigate = useNavigate();

  const { id_caldoc } = crudData;

  const handleGoToDocuments = () => {
    if (id_caldoc) {
      navigate(
        `/${fleetRoutes.fleet}/${crudData.id_auto}/${fleetRoutes.features}/${crudData.id_calitate}/${fleetRoutes.documents}?nrauto=${crudData.nrauto}`,
      );
    } else {
      navigate(
        `/${fleetRoutes.fleet}/${crudData.id_auto}/${fleetRoutes.documents}?nrauto=${crudData.nrauto}`,
      );
    }
  };

  return (
    <>
      <CustomButton
        variant="contained"
        className="mt-1 me-1"
        tooltipTitle={`${
          id_caldoc ? "Document echipament" : "Document masina"
        }`}
        onClick={handleGoToDocuments}>
        <SvgIcon type="DOCUMENTS" />
      </CustomButton>
    </>
  );
}

export default FleetAllDocumentsActionButton;
