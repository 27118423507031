import CustomButton from "../../../../../components/CustomButton";
import SvgIcon from "../../../../../helpers/SvgIcon";
import { fetchDownloadTemperatureRaport } from "../../../../../redux/api/raports.api";
import { useAppDispatch } from "../../../../../redux/hooks";
import { SearchInvoiceDataInterface } from "../../../../../redux/interfaces/raports.interface";

function SearchInvoicesActionButton({
  itemId,
}: {
  itemId: string;
  crudData: SearchInvoiceDataInterface;
  tableContext: any;
}) {
  const dispatch = useAppDispatch();
  const handleDownloadReport = () => {
    dispatch(
      fetchDownloadTemperatureRaport({
        idRaport: itemId,
      }),
    );
  };

  return (
    <div>
      <CustomButton
        variant="contained"
        className="mt-1 me-1"
        tooltipTitle="Editare date autovehicul"
        onClick={handleDownloadReport}>
        <SvgIcon type="DOCUMENTS" />
      </CustomButton>
    </div>
  );
}

export default SearchInvoicesActionButton;
