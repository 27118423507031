import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { TableActions } from "../../../components/tables/tableContext/TableContext";
import TableWidget from "../../../components/tables/TableWidget";
import {
  getSearchInvoicesTableData,
  searchInvoicesTableHeader,
} from "./components/tableConfigs/searchInvoicesTableConfig";
import { fetchSearchInvoice } from "../../../redux/api/raports.api";

import SearchInvoicesActionButton from "./components/TableActionsButtonsComponents/SearchInvoicesActionButton";

function SearchInvoices() {
  const dispatch = useAppDispatch();

  const {
    raports: {
      searchInvoice: {
        data: searchInvoiceData,
        isLoading: isLoadingSearchInvoice,
      },
    },
  } = useAppSelector((state) => ({
    raports: state.raports,
  }));

  const handleTableActions = (action: TableActions) => {
    dispatch(
      fetchSearchInvoice({
        factura: action.tableGlobalSearch,
      }),
    );
  };

  return (
    <TableWidget
      withGlobalSearch
      tableHeaderData={searchInvoicesTableHeader}
      tableItemsData={getSearchInvoicesTableData(searchInvoiceData)}
      tableLoading={isLoadingSearchInvoice}
      ActionButtonsComponent={(props: any) => (
        <SearchInvoicesActionButton {...props} />
      )}
      handleTableActions={handleTableActions}
      withTablePagination={false}
      borderedRow
    />
  );
}

export default SearchInvoices;
