import React from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import useRoutesHelper from "../../../hooks/useRoutesHelper";

import { fetchAllFleetDocs } from "../../../redux/api/fleet.api";

import {
  fleetAllDocumentsTableHeader,
  getFleetAllDocumentsTableData,
} from "./components/tableConfigs/fleetAllDocumentsTableConfig";

import TableWidget from "../../../components/tables/TableWidget";
import Text from "../../../styleguide/Text";
import FleetAllDocumentsActionButton from "./components/TableActionButtonsComponents/FleetAllDocumentsActionButton";

function FleetAllDocumets({ withCarId }: { withCarId?: boolean }) {
  const dispatch = useAppDispatch();

  const { routeMainParam, routeParams } = useRoutesHelper();

  const { carId } = routeMainParam as { carId: string };
  const { nrauto } = routeParams as { nrauto: string };
  const { valability } = routeParams as { valability: number };

  const {
    fleet: {
      fleetAllDocuments: {
        data: { data: fleetAllDocumentsData, total },
        isLoading: isLoading,
      },
    },
  } = useAppSelector((state) => ({
    fleet: state.fleet,
  }));

  const handleTableActions = () => {
    if (valability) {
      dispatch(
        fetchAllFleetDocs({
          idAuto: withCarId ? carId : null,
          page: 1,
          per_page: 50,
          valability: valability,
        }),
      );
    }
  };

  return (
    <TableWidget
      CustomHeaderComponent={() => (
        <div className="d-flex flex-column">
          <Text
            variant="h3"
            component="h3"
            className="card-title mb-4"
            gutterBottom>
            {carId ? `Documente masina ${nrauto}` : "Documente masini"}
          </Text>
        </div>
      )}
      tableHeaderData={fleetAllDocumentsTableHeader}
      tableItemsData={getFleetAllDocumentsTableData(fleetAllDocumentsData)}
      handleTableActions={handleTableActions}
      borderedRow
      tableLoading={isLoading}
      ActionButtonsComponent={(props: any) => (
        <FleetAllDocumentsActionButton {...props} />
      )}
      totalItems={total}
    />
  );
}

export default FleetAllDocumets;
