import {
  TableData,
  TableHeaderCategoryConfig,
} from "../../../../../components/tables/tableContext/TableContext";
import { FleetDocumentsDataInterface } from "../../../../../redux/interfaces/fleet.interface";

export const fleetDocumentsTableHeader: TableHeaderCategoryConfig[] = [
  {
    label: "Document",
    value: "document",
    minW: 125,
  },
  {
    label: "Observatii",
    value: "description",
    minW: 125,
  },
  {
    label: "Valabilitate",
    value: "valability",
    minW: 125,
  },
];

export const getFleetDocumentsTableData = (
  data: FleetDocumentsDataInterface[],
): TableData[] =>
  data.map((item) => {
    return {
      id: item.id_doc,
      crudData: item,
      data: [
        {
          title: item.denumire,
        },
        {
          title: item.doc_obs,
        },
        {
          badges: [
            {
              badgeText: item.doc_ts_start,
              badgeColor: "warning",
            },
            {
              badgeText: item.doc_ts_stop,
              badgeColor: "danger",
            },
          ],
        },
      ],
    };
  });
