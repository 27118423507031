import { memo } from "react";
import { TableItemId } from "../../../../components/tables/TableWidget";
import CustomButton from "../../../../components/CustomButton";
import { TableItemType } from "../../../../components/tables/tableContext/TableContext";

import { useNavigate } from "react-router-dom";
import { expeditionRoutes } from "../../../../router/routesConstants";
import SvgIcon from "../../../../helpers/SvgIcon";

function NodesRouteTableActionButtons({
  itemId,
  crudData,
}: {
  itemId: TableItemId;
  tableItemData: TableItemType[];
  crudData?: any;
  tableContext?: any;
}) {
  const navigate = useNavigate();

  const handleEditNod = () => {
    navigate(
      `/${expeditionRoutes.nodes}/${expeditionRoutes.organize}?driver=${crudData.sofer}&car=${crudData.auto}&nodId=${itemId}`,
    );
    return;
  };

  return (
    <div className="text-start" style={{ width: 250 }}>
      <CustomButton
        variant="contained"
        tooltipTitle="Editeaza nodul"
        className="mx-1 mb-1"
        onClick={handleEditNod}>
        <SvgIcon type="EDIT" />
      </CustomButton>
    </div>
  );
}

export default memo(NodesRouteTableActionButtons);
