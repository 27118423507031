import { useNavigate } from "react-router-dom";
import CustomButton from "../../../../../components/CustomButton";
import SvgIcon from "../../../../../helpers/SvgIcon";
import { useAppDispatch } from "../../../../../redux/hooks";
import { FleetDocumentsDataInterface } from "../../../../../redux/interfaces/fleet.interface";
import { setFleetDocumentData } from "../../../../../redux/reducer/fleet.reducer";
import { fetchDeleteDocument } from "../../../../../redux/api/fleet.api";

function FleetDocumentsActionButton({
  crudData,
  itemId,
}: {
  itemId: string;
  crudData: FleetDocumentsDataInterface;
  tableContext: any;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleEdit = () => {
    dispatch(setFleetDocumentData(crudData));
    navigate(`edit?nrauto=${crudData.nrauto}`);
  };

  const handleDelete = () => {
    dispatch(fetchDeleteDocument({ id: itemId }));
  };

  return (
    <div>
      <CustomButton
        variant="contained"
        className="mt-1 me-1"
        onClick={handleEdit}>
        <SvgIcon type="EDIT" />
      </CustomButton>
      <CustomButton
        variant="contained"
        color="error"
        className="mt-1 me-1"
        withConfirmationModal={{
          modalTitle: "Doriti sa stergeti acest Document?",
          modalLeftButtonColor: "inherit",
          modalRightButtonOnClick: handleDelete,
          modalRightButtonText: "Sterge",
          modalLeftButtonText: "Anuleaza",
        }}>
        <SvgIcon type="DELETE" />
      </CustomButton>
    </div>
  );
}

export default FleetDocumentsActionButton;
