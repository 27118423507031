import {
  TableData,
  TableHeaderCategoryConfig,
} from "../../../../../components/tables/tableContext/TableContext";
import { UsersListInterface } from "../../../../../redux/interfaces/admin.interface";

export const usersTableHeader: TableHeaderCategoryConfig[] = [
  {
    label: "Id",
    value: "id",
    minW: 125,
  },
  {
    label: "Username",
    value: "username",
    minW: 125,
  },
  {
    label: "Nume",
    value: "firstName",
    minW: 125,
  },

  {
    label: "Prenume",
    value: "secondName",
    minW: 125,
  },
];

export const getUsersTableData = (data: UsersListInterface[]): TableData[] =>
  data.map((item) => {
    return {
      id: item.id_user,
      crudData: item,
      data: [
        {
          title: item.id_user,
        },
        {
          title: item.username,
        },
        {
          title: item.nume,
        },
        {
          title: item.prenume,
        },
      ],
    };
  });
