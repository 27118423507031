import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import ContentCard from "../../../components/misc/ContentCard";
import Text from "../../../styleguide/Text";
import SelectableItem from "../../../components/misc/SelectableItem/SelectableItem";

import { fetchFleet } from "../../../redux/api/fleet.api";

import AssignDriverIntranod from "./AssignDriverIntranod";
import AssignDriverRoute from "./AssignDriverRoute";

function AssignDriver({ withUpdate = false }) {
  const dispatch = useAppDispatch();

  const [isInternode, setIsInternode] = useState(false);

  const {
    assign: { assignedDriverData },
  } = useAppSelector((state) => ({
    assign: state.assign,
  }));

  const { nume, prenume, id_ruta_intranod } = assignedDriverData || {};

  useEffect(() => {
    dispatch(
      fetchFleet({
        perPage: 10000,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    setIsInternode(!!id_ruta_intranod);
  }, [id_ruta_intranod]);

  if (!assignedDriverData) {
    return (
      <ContentCard>
        <h2>Sofer inexistent</h2>
      </ContentCard>
    );
  }

  return (
    <ContentCard
      CardHeader={() => (
        <div className="d-flex align-items-center justify-content-between w-100">
          <Text variant="h1" className="mb-5">
            Asignare ruta pentru soferul {prenume} {nume}
          </Text>
          <SelectableItem
            name={"internode"}
            title="Intranod"
            className="px-5"
            onClick={() => {
              setIsInternode((prev) => !prev);
            }}
            checked={isInternode}
            type="checkbox"
          />
        </div>
      )}>
      {isInternode ? (
        <AssignDriverIntranod withUpdate={withUpdate} />
      ) : (
        <AssignDriverRoute withUpdate={withUpdate} />
      )}
    </ContentCard>
  );
}

export default AssignDriver;
