import React from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { fetchUsers } from "../../../redux/api/admin.api";
import { TableActions } from "../../../components/tables/tableContext/TableContext";
import TableWidget  from "../../../components/tables/TableWidget";
import UsersActionButton from "./components/TableActionsButtonsComponents/UsersActionButton";
import {
  getUsersTableData,
  usersTableHeader,
} from "./components/tableConfigs/usersTableConfig";

function Users() {
  const dispatch = useAppDispatch();

  const {
    admin: {
      users: {
        data: { data: users, total },
        isLoading,
      },
    },
  } = useAppSelector((state) => ({
    admin: state.admin,
  }));

  const handleTableActions = (action: TableActions) => {
    if (action.tablePagination.page && action.tablePagination.rowsPerPage) {
      dispatch(
        fetchUsers({
          page: action.tablePagination.page,
          perPage: action.tablePagination.rowsPerPage,
        }),
      );
    }
  };

  return (
    <TableWidget
      tableTitle={"Utilizatori"}
      tableHeaderData={usersTableHeader}
      tableItemsData={getUsersTableData(users)}
      totalItems={total}
      borderedRow
      tableLoading={isLoading}
      ActionButtonsComponent={(props: any) => <UsersActionButton {...props} />}
      handleTableActions={handleTableActions}
      showSearchForCategories={false}
    />
  );
}

export default Users;
