export default {
  // ADMIN
  PREVIEW_USERS: "preview_users",
  CREATE_USER: "create_user",
  EDIT_USER: "edit_user",
  ADMINISTRATE_USER_ROLES: "administrate_user_web_roles",
  ADMINISTRATE_USER_MOBILE_ROLES: "administrate_user_mobile_roles",
  // FLEET`
  PREVIEW_FLEET: "preview_fleet",
};
