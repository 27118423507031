import { createSlice } from "@reduxjs/toolkit";

import {
  fetchUserDetails,
  fetchUserPermissionsMobile,
  fetchUserPermissionsWeb,
  fetchUsers,
} from "../api/admin.api";
import {
  PermissionsMobileInterface,
  PermissionsWebInterface,
  UserInterface,
  UsersInterface,
} from "../interfaces/admin.interface";

export interface StateAdminInterface {
  users: UsersInterface;
  user: UserInterface;
  permissionsWeb: PermissionsWebInterface;
  permissionsMobile: PermissionsMobileInterface;
}

export const defaultStateAdmin: StateAdminInterface = {
  users: {
    data: {
      current_page: 1,
      data: [],
      last_page: 1,
      perPage: 1,
      total: 1,
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  user: {
    data: null,
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  permissionsWeb: {
    data: [],
    isLoadingPermissionsWeb: false,
    isErrorPermissionsWeb: false,
    errorMessagePermissionsWeb: "",
  },
  permissionsMobile: {
    data: [],
    isLoadingPermissionsMobile: false,
    isErrorPermissionsMobile: false,
    errorMessagePermissionsMobile: "",
  },
};

const admin = createSlice({
  name: "admin",
  initialState: defaultStateAdmin,
  reducers: {
    removeUserFromUsers(state, action: any) {
      const newUsers = state.users.data.data.filter(
        (user) => user.id_user !== action.payload,
      );
      return {
        ...state,
        users: {
          ...state.users,
          data: {
            ...state.users.data,
            users: newUsers,
          },
        },
      };
    },
  },
  extraReducers: (builder) => {
    // users start
    builder.addCase(fetchUsers.pending, (state) => {
      return {
        ...state,
        users: {
          ...state.users,
          ...defaultStateAdmin.users,
          isLoadingUsers: true,
        },
      };
    });
    builder.addCase(fetchUsers.rejected, (state, action: any) => {
      return {
        ...state,
        users: {
          ...state.users,
          isLoadingUsers: false,
          isErrorUsers: true,
          errorMessageUsers: action.payload.message,
        },
      };
    });
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      return {
        ...state,
        users: {
          ...state.users,
          ...defaultStateAdmin.users,
          data: action.payload,
        },
      };
    });
    // users end
    // user start
    builder.addCase(fetchUserDetails.pending, (state) => {
      return {
        ...state,
        user: {
          ...state.user,
          ...defaultStateAdmin.user,
          isLoadingUser: true,
        },
      };
    });
    builder.addCase(fetchUserDetails.rejected, (state, action: any) => {
      return {
        ...state,
        user: {
          ...state.user,
          isLoadingUser: false,
          isErrorUser: true,
          errorMessageUser: action.payload.message,
        },
      };
    });
    builder.addCase(fetchUserDetails.fulfilled, (state, action) => {
      return {
        ...state,
        user: {
          ...state.user,
          ...defaultStateAdmin.user,
          data: action.payload,
        },
      };
    });
    // user end
    // user permission  web start
    builder.addCase(fetchUserPermissionsWeb.pending, (state) => {
      return {
        ...state,
        permissionsWeb: {
          ...state.permissionsWeb,
          ...defaultStateAdmin.permissionsWeb,
          isLoadingPermissions: true,
        },
      };
    });
    builder.addCase(fetchUserPermissionsWeb.rejected, (state, action: any) => {
      return {
        ...state,
        permissionsWeb: {
          ...state.permissionsWeb,
          isLoadingPermissions: false,
          isErrorPermissions: true,
          errorMessagePermissions: action.payload.message,
        },
      };
    });
    builder.addCase(fetchUserPermissionsWeb.fulfilled, (state, action) => {
      return {
        ...state,
        permissionsWeb: {
          ...state.permissionsWeb,
          ...defaultStateAdmin.permissionsWeb,
          data: action.payload,
        },
      };
    });
    // user permission web end
    // user permissions mobile start
    builder.addCase(fetchUserPermissionsMobile.pending, (state) => {
      return {
        ...state,
        permissionsMobile: {
          ...state.permissionsMobile,
          ...defaultStateAdmin.permissionsMobile,
          isLoadingPermissionsMobile: true,
        },
      };
    });
    builder.addCase(
      fetchUserPermissionsMobile.rejected,
      (state, action: any) => {
        return {
          ...state,
          permissionsMobile: {
            ...state.permissionsMobile,
            isLoadingPermissionsMobile: false,
            isErrorPermissionsMobile: true,
            errorMessagePermissionsMobile: action.payload.message,
          },
        };
      },
    );
    builder.addCase(fetchUserPermissionsMobile.fulfilled, (state, action) => {
      return {
        ...state,
        permissionsMobile: {
          ...state.permissionsMobile,
          ...defaultStateAdmin.permissionsMobile,
          data: action.payload,
        },
      };
    });
    // user permissions mobile end
  },
});

export const { removeUserFromUsers } = admin.actions;

export default admin.reducer;
