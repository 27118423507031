import { useMemo } from "react";
import { useAppSelector } from "../redux/hooks";
import {
  adminRoutes,
  assignRoutes,
  expeditionRoutes,
  fleetRoutes,
  reportsRoutes,
} from "./routesConstants";

import userRoles from "../constants/userRoles";

interface DashboardCategories {
  color: string;
  title: string;
  subtitle?: string;
  subtitleValue?: string;
  categories: {
    id: string;
    title: string;
    path: string;
    subtitle?: string;
    value?: string | number;
    isIncreasing?: boolean;
  }[];
  navigationLinks?: {
    link: string;
    label: string;
  }[];
}

function useAppMenuRoutes() {
  const {
    user: { userRoutesAccess },
  } = useAppSelector((state) => ({
    user: state.user,
  }));

  const fleepMenuRoutes = [
    {
      id: userRoles.PREVIEW_FLEET,
      title: "Flota",
      path: `/${fleetRoutes.fleet}`,
    },
    {
      id: userRoles.PREVIEW_FLEET,
      title: "Documente masini",
      path: `/${fleetRoutes.carsDocuments}`,
    },
  ].filter((route) => userRoutesAccess?.includes(route.id));

  const expeditionMenuRoutes = [
    {
      id: userRoles.PREVIEW_FLEET,
      title: "Program puncte de lucru",
      path: `/${expeditionRoutes.workingPoints}`,
    },
    {
      id: userRoles.PREVIEW_FLEET,
      title: "Program rute de transport",
      path: `/${expeditionRoutes.routes}`,
    },
    {
      id: userRoles.PREVIEW_FLEET,
      title: "Noduri",
      path: `/${expeditionRoutes.nodes}`,
    },
  ].filter((route) => userRoutesAccess?.includes(route.id));

  const assignMenuRoutes = [
    {
      id: userRoles.PREVIEW_FLEET,
      title: "Alocare ruta",
      path: `/${assignRoutes.assign}`,
    },
  ].filter((route) => userRoutesAccess?.includes(route.id));

  const reportsMenuRoutes = [
    {
      id: userRoles.PREVIEW_FLEET,
      title: "Cauta facturi AWB",
      path: `/${reportsRoutes.searchInvoicesAwb}`,
    },
  ].filter((route) => userRoutesAccess?.includes(route.id));

  const adminMenuRoutes = [
    {
      id: userRoles.PREVIEW_USERS,
      title: "Utilizatori",
      path: `/${adminRoutes.users}`,
    },
    {
      id: userRoles.CREATE_USER,
      title: "Creeaza utilizator",
      path: `/${adminRoutes.users}/${adminRoutes.createUser}`,
    },
  ].filter((route) => userRoutesAccess?.includes(route.id));

  const categories: DashboardCategories[] = useMemo(
    () =>
      [
        {
          color: "info",
          title: "Flota",
          categories: fleepMenuRoutes,
        },
        {
          color: "info",
          title: "Expeditie",
          categories: expeditionMenuRoutes,
        },
        {
          color: "info",
          title: "Transport",
          categories: assignMenuRoutes,
        },
        {
          color: "info",
          title: "Rapoarte",
          categories: reportsMenuRoutes,
        },
        {
          color: "primary",
          title: "Administrare",
          categories: adminMenuRoutes,
        },
      ].filter((category) => category.categories.length > 0),
    [
      adminMenuRoutes,
      fleepMenuRoutes,
      expeditionMenuRoutes,
      assignMenuRoutes,
      reportsMenuRoutes,
    ],
  );

  return categories;
}

export default useAppMenuRoutes;
