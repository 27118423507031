import {
  TableData,
  TableHeaderCategoryConfig,
} from "../../../../../components/tables/tableContext/TableContext";
import { SearchInvoiceDataInterface } from "../../../../../redux/interfaces/raports.interface";

export const searchInvoicesTableHeader: TableHeaderCategoryConfig[] = [
  {
    label: "AWB",
    value: "awb",
    minW: 125,
  },
  {
    label: "Status",
    value: "status",
    minW: 125,
  },
];

export const getSearchInvoicesTableData = (
  data: SearchInvoiceDataInterface[],
): TableData[] =>
  data.map((item) => {
    return {
      id: item.id_doctrans_detl,
      crudData: item,
      data: [
        {
          title: item.doc_trans,
        },
        {
          title: item.status,
        },
      ],
    };
  });
