import { createSlice } from "@reduxjs/toolkit";

export const defaultStateGeneral = {};

const general = createSlice({
  name: "general",
  initialState: defaultStateGeneral,
  reducers: {},
  // extraReducers: (builder) => {},
});

// export const { removeProducts, setLastSavedVerifiedCommands } = general.actions;

export default general.reducer;
