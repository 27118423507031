import moment from "moment";

export const isTodayDate = (date: Date | string) => {
  return moment().format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD");
};

export const isTomorrowDate = (date: Date | string) => {
  return (
    moment().add(1, "day").format("YYYY-MM-DD") ===
    moment(date).format("YYYY-MM-DD")
  );
};

export const getCurrentDate = () => {
  return moment().format("YYYY-MM-DD");
};

export const getCurrentTime = () => {
  return moment().format("HH:mm:ss");
};

export const dateToString = (
  date?: Date | null | string,
  invalidDateLabel = "",
) => {
  if (!date) return invalidDateLabel;
  const dateMoment = moment(date).format("DD-MM-YYYY");
  if (dateMoment === "Invalid date") {
    return invalidDateLabel || dateMoment;
  }
  return dateMoment;
};

export const dateForDatabase = (date?: string | Date | null) => {
  if (!date) return "";
  return moment(date).format("YYYY-MM-DD");
};

export const getDateAndTime = (
  date?: Date | null | string,
  invalidDateLabel = "",
) => {
  if (!date) return invalidDateLabel;
  const dateMoment = moment(date).format("DD-MM-YYYY HH:mm");
  if (dateMoment === "Invalid date") {
    return invalidDateLabel || dateMoment;
  }
  return dateMoment;
};

export const getHoursAndMinutes = (date?: Date | null) => {
  if (!date) return "";
  return moment(date).format("HH:mm");
};

export const getWeekDays = (locale: string) => {
  const baseDate = new Date(Date.UTC(2017, 0, 2)); // just a Monday
  const weekDays = [];
  for (let i = 1; i <= 7; i++) {
    weekDays.push({
      id: i,
      name: baseDate.toLocaleDateString(locale, { weekday: "long" }),
    });
    baseDate.setDate(baseDate.getDate() + 1);
  }
  return weekDays;
};

export const getDateWithoutNthMonths = (months: number) => {
  return new Date(new Date().setMonth(new Date().getMonth() - months));
};

export const getDateWithoutNthDays = (day: number) => {
  return moment().subtract(day, "days").toDate();
};

// when you run this function you should put date like this "getStartFromSpecificDate("01 April, 2024")" "
export const getStartFromSpecificDate = (date?: Date | null | string) => {
  return moment(date).toDate();
};

export const getLastDayOfMonth = (
  date: Date | string,
  withFullDate?: boolean,
) => {
  const mask = withFullDate ? "YYYY-MM-DD " : "DD";
  const endOfMonth = moment(date).endOf("month").format(mask);
  return endOfMonth;
};

export const getFirstDayOfMonth = (
  date: Date | string,
  withFullDate?: boolean,
) => {
  const mask = withFullDate ? "YYYY-MM-DD " : "DD";
  const startOfMonth = moment(date).startOf("month").format(mask);
  return startOfMonth;
};

export const isBeforeToday = (date: Date | string) => {
  const dateToCheck = moment(date); // Replace with your date
  const today = moment().startOf("day"); // Today's date at midnight

  return dateToCheck.isBefore(today);
};
