import { memo } from "react";
import { KTSVG } from "../../../../../helpers/KTSVG";

import { TableItemId } from "../../../../../components/tables/TableWidget";
import CustomButton from "../../../../../components/CustomButton";

import { TableItemType } from "../../../../../components/tables/tableContext/TableContext";
import { expeditionRoutes } from "../../../../../router/routesConstants";
import { ScheduleWorkingPointsListInterface } from "../../../../../redux/interfaces/expedition.interface";

function ExpeditionScheduleWorkingPointsTableActionButtons({
  itemId,
  crudData,
}: {
  itemId: TableItemId;
  tableItemData: TableItemType[];
  crudData?: ScheduleWorkingPointsListInterface;
  tableContext?: any;
}) {
  const goToOrganizeWorkingPoint = () => {
    return `/${expeditionRoutes.workingPoints}/${expeditionRoutes.organize}?id=${itemId}&routeId=${crudData?.id_ruta}&adress=${crudData?.adresa}&deliveryPoint=${crudData?.denumire}`;
  };

  return (
    <div className="text-start" style={{ width: 250 }}>
      <CustomButton
        variant="contained"
        tooltipTitle="Organizare"
        className="mx-1 mb-1"
        navigate={goToOrganizeWorkingPoint()}>
        <KTSVG
          path="/media/svg/reception/document.svg"
          className="svg-icon-1"
          fill="white"
        />
      </CustomButton>
    </div>
  );
}

export default memo(ExpeditionScheduleWorkingPointsTableActionButtons);
