import {
  TableData,
  TableHeaderCategoryConfig,
} from "../../../../../components/tables/tableContext/TableContext";
import { FleetFeaturesDataInterface } from "../../../../../redux/interfaces/fleet.interface";

export const fleetFeaturesTableHeader: TableHeaderCategoryConfig[] = [
  {
    label: "Denumire",
    value: "denumire",
    minW: 125,
  },
  {
    label: "Model echipament",
    value: "model_ech",
    minW: 125,
  },
  {
    label: "Model capacit",
    value: "model_capacit",
    minW: 125,
  },
  {
    label: "Model ser",
    value: "model_ser",
    minW: 125,
  },
];

export const getFleetFeaturesTableData = (
  data: FleetFeaturesDataInterface[],
): TableData[] =>
  data.map((item) => {
    return {
      id: item.id_calitate,
      crudData: item,
      data: [
        {
          title: item.denumire,
        },
        {
          title: item.model_ech,
        },
        {
          title: item.model_capacit,
        },

        {
          title: item.model_ser,
        },
      ],
    };
  });
