import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiRequest,
  errorFormatHandler,
  isDevelop,
} from "../../helpers/apiHelper";

const apiEnv = isDevelop
  ? process.env.REACT_APP_TEST_API_URL
  : process.env.REACT_APP_PUBLIC_API_URL;

export const fetchSearchInvoice = createAsyncThunk(
  "/raports/cautaFacturaAwb",
  async (
    params: {
      factura: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    console.log("params", params);
    try {
      const { data: responseData } = await apiRequest(
        "/cautaFacturaAwb",
        "POST",
        params,
      );
      console.log("responseData", responseData);
      return responseData;
    } catch (err: any) {
      console.warn("/raports/cautaFacturaAwb", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchDownloadTemperatureRaport = createAsyncThunk(
  "/raports/fetchDownloadTemperatureRaport",
  async (
    {
      idRaport,
    }: {
      idRaport: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      window
        .open(`${apiEnv}/downloadTemperatureRaport/${idRaport}`, "_blank")
        ?.focus();
    } catch (err: any) {
      console.warn("/raports/fetchDownloadTemperatureRaport", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);
